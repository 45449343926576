import { AnimatePresence } from 'framer-motion';
import { FC, useState } from 'react';
import LogInModal from '~/components/LogInModal/LogInModal';
import Dots from '~/icons/Dots';
import { Article } from '~/types';

import ArticleScoreModal from '../ArticleScoreModal/ArticleScoreModal';
import ArticleFollowTopicsModal from '../ArticleTopicsModals/ArticleFollowTopicsModal';
import ArticleIgnoreTopicsModal from '../ArticleTopicsModals/ArticleIgnoreTopicsModal';
import ContentReportModal from '../ContentReportModal/ContentReportModal';
import styles from './ArticleDotsActions.module.scss';
import ArticleDotsDropdown from './ArticleDotsDropdown';

enum DropdownPosition {
  Top = 'top',
  Bottom = 'bottom',
}

interface Props {
  article: Article;
}

const ArticleDotsActions: FC<Props> = ({ article }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [showScoreModal, setShowScoreModal] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);
  const [showFollowModal, setShowFollowModal] = useState(false);
  const [showIgnoreModal, setShowIgnoreModal] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState<DropdownPosition>(
    DropdownPosition.Top,
  );

  return (
    <>
      <div className={styles.buttonHolder}>
        <button
          className={styles.button}
          onClick={(e) => {
            setShowDropdown((prev) => !prev);
            setDropdownPosition(
              window.innerHeight - e.clientY < 245
                ? DropdownPosition.Top
                : DropdownPosition.Bottom,
            );
          }}
        >
          <Dots />
        </button>
        {showDropdown && (
          <ArticleDotsDropdown
            articleId={article.id}
            dropdownPosition={dropdownPosition}
            onClose={() => setShowDropdown(false)}
            setShowScoreModal={setShowScoreModal}
            setShowReportModal={setShowReportModal}
            setShowFollowModal={setShowFollowModal}
            setShowIgnoreModal={setShowIgnoreModal}
            setShowLoginModal={setShowLoginModal}
          />
        )}
      </div>
      <AnimatePresence>
        {showScoreModal && (
          <ArticleScoreModal
            article={article}
            onClose={() => setShowScoreModal(false)}
          />
        )}
        {showReportModal && (
          <ContentReportModal
            onClose={() => setShowReportModal(false)}
            articleId={article.id}
          />
        )}
        {showFollowModal && (
          <ArticleFollowTopicsModal
            tags={article.tags}
            onClose={() => setShowFollowModal(false)}
          />
        )}
        {showIgnoreModal && (
          <ArticleIgnoreTopicsModal
            tags={article.tags}
            onClose={() => setShowIgnoreModal(false)}
          />
        )}
        {showLoginModal && (
          <LogInModal onClose={() => setShowLoginModal(false)} />
        )}
      </AnimatePresence>
    </>
  );
};

export default ArticleDotsActions;
