import classNames from 'classnames';
import { FC, useRef } from 'react';
import { useUser } from '~/context/UserContext';
import useOnClickOutside from '~/hooks/useOnClickOutside';
import LinkIcon from '~/icons/LinkIcon';
import ReportRectangle from '~/icons/ReportRectangle';
import ThumbsDown from '~/icons/ThumbsDown';
import ThumbsUp from '~/icons/ThumbsUp';

import styles from './ArticleDotsDropdown.module.scss';

interface Props {
  articleId: number;
  dropdownPosition: 'bottom' | 'top';
  onClose: () => void;
  setShowScoreModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowReportModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowFollowModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowIgnoreModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowLoginModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const ArticleDotsDropdown: FC<Props> = ({
  articleId,
  dropdownPosition,
  onClose,
  setShowReportModal,
  setShowFollowModal,
  setShowIgnoreModal,
  setShowLoginModal,
}) => {
  const { user } = useUser();

  const componentRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(componentRef, onClose);

  return (
    <div
      ref={componentRef}
      className={classNames(styles.dropdown, {
        [styles.top]: dropdownPosition === 'top',
        [styles.bottom]: dropdownPosition === 'bottom',
      })}
    >
      <button
        className={styles.actionButton}
        onClick={() => {
          if (!user) {
            return setShowLoginModal(true);
          }
          setShowFollowModal(true);
          onClose();
        }}
      >
        <ThumbsUp />
        See more like this
      </button>
      <button
        className={styles.actionButton}
        onClick={() => {
          if (!user) {
            return setShowLoginModal(true);
          }
          setShowIgnoreModal(true);
          onClose();
        }}
      >
        <ThumbsDown />
        See less like this
      </button>
      <button
        className={styles.actionButton}
        onClick={() => {
          setShowReportModal(true);
          onClose();
        }}
      >
        <ReportRectangle />
        Report
      </button>
      {user?.is_admin && (
        <div className={styles.adminActions}>
          <a
            className={styles.actionButton}
            target='blank'
            href={`https://api.bp.news/admin/articles/${articleId}/edit`}
            rel='nofollow noreferrer'
          >
            <LinkIcon />
            Admin
          </a>
        </div>
      )}
    </div>
  );
};

export default ArticleDotsDropdown;
